<template>
  <Header title="Executive Benefits" />

  <Container>
    <h2>Custom Designed Executive Benefits</h2>
    <p>
      Executive groups are the corporations' brain trust. They tend to put in
      more hours, are responsible for making the most difficult and important
      decisions and yet often have the lowest level of benefits in the
      organization relative to their compensation.
      <br /><br />
      The typical group benefits package provides a solid base of protection in
      all areas for the majority of the staff in a corporation. Most of the
      staff have a good percentage of their income replaced and health care
      provided for by a standard group benefit plan.
      <br /><br />
      However, there are limits on the coverage amount and quality of benefits
      provided to the group as a whole. As incomes increase, in a corporate
      environment, often there are a lower percentage of benefits covered. This
      results from ceiling on formulas and inferior contract definitions. This
      protection gap creates a form of reverse discrimination for high-income
      earners.
      <br /><br />
      As a result, corporations are increasingly using alternate methods to
      ensure that their key people are receiving the best possible programs.
      Custom designed executive benefits are one method that allows management
      to enhance compensation both before and after retirement in the most tax
      effective manner.
      <br /><br />
      Bryan and Hedden created ExecPlus, in conjunction with their national
      consulting partner, to provide the following features to enhanced
      Executive benefit programs.
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Improved quality of benefit package</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Short and long term financial security</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Comprehensive benefit coverage</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Flexibility</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Executive tax, retirement and estate planning</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Quality post-retirement benefits</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Adapts to changing needs to individual</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Portability</p></li>
    </ul>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
